import React from "react"
import { Link } from "gatsby"
import { Row } from 'react-bootstrap'
import { PageWrapper } from "~components"
//import Layout from "../components/layout"

const NotFound = () => {
  return (
    
    <PageWrapper>
      <div className="justify-content-center">
      <h1 >Page not found</h1>
    <p>
      <Link to="/">Head home</Link>
    </p>
    </div>
    </PageWrapper>
        
    
      
    
  )
}

export default NotFound